import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../services/users.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  account = false;
  address: any;
  verified = false;
  mobileDropDownShow = false;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private userService: UsersService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.address = localStorage.getItem('account_address');

    if (!this.address) {
      this.account = false;
    } else {
      this.account = true;
    }
    this.userService.checkUser(this.address).subscribe((data) => {
      if (data.success) {
        this.verified = true;
      }
    });
  }

  open(content) {
    this.modalService.open(content, { size: 'm', centered: true });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  menuShow(){
    this.mobileDropDownShow = false;
  }

  toggleShow(){
    this.mobileDropDownShow = !this.mobileDropDownShow;
  }

  profileAccount() {
    localStorage.setItem('profile', 'account');
  }

  profileInventory() {
    localStorage.setItem('profile', 'inventory');
  }

  profileWallet() {
    localStorage.setItem('profile', 'wallet');
  }

  logout() {
    localStorage.removeItem('account_address');
    this.router.navigate(['product']).then(() => {
      window.location.reload();
    });
  }

  submit(email, password) {
    this.spinner.show();
    if (email !== '' && password !== '') {
      let md5 = new Md5();
      let hashPassword = md5.appendAsciiStr(password).end();
      let data = {
        email: email,
        password: hashPassword,
        wallet_address: localStorage.getItem('account_address'),
      };
      this.userService.bindUser(data).subscribe((data) => {
        if (data.success) {
          this.spinner.hide();
          Swal.fire({
            title: 'Success!',
            text: 'Your game account is now linked to your wallet!',
            icon: 'success',
          });
          this.modalService.dismissAll('gameInfo');
        } else {
          console.log(1);
          this.spinner.hide();
          Swal.fire({
            title: 'Error!',
            text: 'Binding failed. Invalid game email or password. ',
            icon: 'error',
          });
        }
      });
    }
  }
}
