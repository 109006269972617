<div class="mt-2 mb-4 mx-auto" aria-label="pagination" style="display: flex;  justify-content: center;">
  <ul class="pagination">
	<li class="page-item d-sm-none"><span class="page-link page-link-static">{{ page + 1 }} / {{ maxPage }}</span></li>
	<li class="page-item" style="width: 30px;">
		<a class="page-link page-btn-show" (click)="changePage(page - 1)" href="javascript:void(0);" aria-label="Previous" [ngClass]="{ 'page-btn-hide':!(0 < page) }">
			<
		</a>
	</li>
	<li class="page-item d-none d-sm-block page-btn-show" [ngClass]="{ 'page-btn-hide':!(0 < page - 2) }">
		<a class="page-link" (click)="changePage(0)" href="javascript:void(0);">{{ 1 }}</a>
	</li>
	<li class="page-item d-none d-sm-block page-btn-show" style="color:#ffffff" [ngClass]="{ 'page-btn-hide': (page-3 <= 0)}">...</li>
	<li class="page-item d-none d-sm-block page-btn-show" [ngClass]="{ 'page-btn-hide':!(0 < page - 1) }">
		<a class="page-link" (click)="changePage(page - 2)" href="javascript:void(0);">{{ page - 1 }}</a>
	</li>
	<li class="page-item d-none d-sm-block page-btn-show" [ngClass]="{ 'page-btn-hide':!(0 < page) }">
		<a (click)="changePage(page - 1)" href="javascript:void(0);" class="page-link">{{ page }}</a>
	</li>
	<li class="page-item active d-none d-sm-block" aria-current="page">
		<span class="page-link">{{ page + 1 }}<span class="visually-hidden">(current)</span></span>
	</li>
	<li class="page-item d-none d-sm-block page-btn-show" [ngClass]="{ 'page-btn-hide':!(maxPage > page + 1) }">
		<a (click)="changePage(page + 1)" href="javascript:void(0);" class="page-link">{{ page + 2 }}</a>
	</li>
	<li class="page-item d-none d-sm-block page-btn-show" [ngClass]="{ 'page-btn-hide':!(maxPage > page + 2) }">
		<a (click)="changePage(page + 2)" class="page-link" href="javascript:void(0);">{{ page + 3 }}</a>
	</li>
	<li class="page-item d-none d-sm-block page-btn-show" style="color:#ffffff" [ngClass]="{ 'page-btn-hide': (page + 4 >= maxPage)}">...</li>
	<li class="page-item d-none d-sm-block page-btn-show" [ngClass]="{ 'page-btn-hide':!(maxPage > page + 3) }">
		<a (click)="changePage(maxPage - 1)" class="page-link" href="javascript:void(0);">{{ maxPage }}</a>
	</li>
	<li class="page-item" style="width: 30px;">	
		<a class="page-link page-btn-show" (click)="changePage(page + 1)" href="javascript:void(0);" aria-label="Next "  [ngClass]="{ 'page-btn-hide':!(maxPage -1 > page) }">
			>
		</a>
	</li>
  </ul>
</div>