import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'mtl-marketplace';

  ngOnInit() {
    this.checkMetaMaskExtension();
  }

  checkMetaMaskExtension() {
    const metaMaskEnabled = window.ethereum ? true : false;
    // show popup if not enabled?
    if (!metaMaskEnabled) {
      // alert("MetaMask Extension is not installed, please install MetaMask extension.");
    }
  }
}
