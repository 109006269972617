<section style="margin-top: 60px">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashboard-card-bg">
          <div class="dashboard-tabs">
            <div class="dashboard-tabs__list">
              <a
                href="#tab-last24h"
                class="dashboard-tabs__item"
                [ngClass]="{
                  'dashboard-tabs__item--active': tab === 'last24h'
                }"
                (click)="$event.preventDefault(); tab = 'last24h'"
                >Last 24h</a
              >

              <a
                href="#tab-7days"
                class="dashboard-tabs__item"
                [ngClass]="{
                  'dashboard-tabs__item--active': tab === '7days'
                }"
                (click)="$event.preventDefault(); tab = '7days'"
                >7 days</a
              >

              <a
                href="#tab-30days"
                class="dashboard-tabs__item"
                [ngClass]="{ 'dashboard-tabs__item--active': tab === '30days' }"
                (click)="$event.preventDefault(); tab = '30days'"
                >30 days</a
              >
            </div>
            <div class="dashboard-tabs__content">
              <!--24hs-->
              <div
                class="dashboard-tabs__pane"
                id="tab-last24h"
                [ngClass]="{
                  'dashboard-tabs__pane--active': tab === 'last24h'
                }"
               
              >
                <div class="row col-lg-11 mx-auto">
                  <div class="col-lg-4">
                    <div class="row dashboard-card">
                      <div class="col-4">
                        <img src="assets/images/total-sales.svg" />
                      </div>
                      <div class="col-8">
                        <div class="dashboard-result">
                          <h4>Total sales</h4>
                          <p>
                            {{ tradingOverView?.last_24h.total_sales ? tradingOverView?.last_24h.total_sales : '0' }}
                          </p>
                           <span>$2489.88</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="row dashboard-card">
                      <div class="col-4">
                        <img src="assets/images/total-volume.svg" />
                      </div>
                      <div class="col-8">
                        <div class="dashboard-result">
                          <h4>Total volume</h4>
                          <p>{{ tradingOverView?.last_24h.total_volume ?  tradingOverView?.last_24h.total_volume : '0' }}</p>
                          <span>$2489.88</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="row dashboard-card">
                      <div class="col-4">
                        <img src="assets/images/average-price.svg" />
                      </div>
                      <div class="col-8">
                        <div class="dashboard-result">
                          <h4>Average price</h4>
                          <p>{{ tradingOverView?.last_24h.average_price ? tradingOverView?.last_24h.average_price : '0' }}</p>
                           <span>$5.67</span> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 7days -->
              <div
                class="dashboard-tabs__pane"
                id="tab-7days"
                [ngClass]="{
                  'dashboard-tabs__pane--active': tab === '7days'
                }"
                *ngIf="tradingOverView"
              >
                <div class="row col-lg-11 mx-auto">
                  <div class="col-lg-4">
                    <div class="row dashboard-card">
                      <div class="col-4">
                        <img src="assets/images/total-sales.svg" />
                      </div>
                      <div class="col-8">
                        <div class="dashboard-result">
                          <h4>Total sales</h4>
                          <p>{{ tradingOverView.last_7d.total_sales }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="row dashboard-card">
                      <div class="col-4">
                        <img src="assets/images/total-volume.svg" />
                      </div>
                      <div class="col-8">
                        <div class="dashboard-result">
                          <h4>Total volume</h4>
                          <p>{{ tradingOverView.last_7d.total_volume }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="row dashboard-card">
                      <div class="col-4">
                        <img src="assets/images/average-price.svg" />
                      </div>
                      <div class="col-8">
                        <div class="dashboard-result">
                          <h4>Average price</h4>
                          <p>{{ tradingOverView.last_7d.average_price }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--30days-->
              <div
                class="dashboard-tabs__pane"
                id="tab-30days"
                [ngClass]="{
                  'dashboard-tabs__pane--active': tab === '30days'
                }"
                *ngIf="tradingOverView"
              >
                <div class="row col-lg-11 mx-auto">
                  <div class="col-lg-4">
                    <div class="row dashboard-card">
                      <div class="col-4">
                        <img src="assets/images/total-sales.svg" />
                      </div>
                      <div class="col-8">
                        <div class="dashboard-result">
                          <h4>Total sales</h4>
                          <p>{{ tradingOverView.last_30d.total_sales }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="row dashboard-card">
                      <div class="col-4">
                        <img src="assets/images/total-volume.svg" />
                      </div>
                      <div class="col-8">
                        <div class="dashboard-result">
                          <h4>Total volume</h4>
                          <p>{{ tradingOverView.last_30d.total_volume }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="row dashboard-card">
                      <div class="col-4">
                        <img src="assets/images/average-price.svg" />
                      </div>
                      <div class="col-8">
                        <div class="dashboard-result">
                          <h4>Average price</h4>
                          <p>{{ tradingOverView.last_30d.average_price }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="dashboard-card-bg">
          <div class="row">
            <div class="col-lg-12">
              <div class="dashboard-tabs">
                <div class="dashboard-tabs__list">
                  <a
                    href="#tab-lifeListed"
                    class="dashboard-tabs__item"
                    [ngClass]="{
                      'dashboard-tabs__item--active': tabListed === 'lifeListed'
                    }"
                    (click)="$event.preventDefault(); tabListed = 'lifeListed'"
                    i18n
                    >Savanna life</a
                  >
                  <a
                    href="#tab-landListed"
                    class="dashboard-tabs__item"
                    [ngClass]="{
                      'dashboard-tabs__item--active': tabListed === 'landListed'
                    }"
                    (click)="$event.preventDefault(); tabListed = 'landListed'"
                    i18n
                    >Savanna land</a
                  >
                </div>
                <div class="dashboard-tabs__content">
                  <div
                    class="dashboard-tabs__pane"
                    id="tab-lifeListed"
                    [ngClass]="{
                      'dashboard-tabs__pane--active': tabListed === 'lifeListed'
                    }"
                  >
                    <div class="row m-0">
                      <div *ngIf="!nftList" class="dashboard-loading">
                        <img src="assets/images/loading.svg" width="75px" />
                      </div>
                      <div class="col-lg-4" *ngFor="let nft of nftList">
                        <a href="product/detail/{{ nft._id }}">
                          <div class="recent-card">
                            <div class="row mb-3">
                              <div class="col-8">
                                <div class="name-label">{{ nft.animal_name }}</div>
                              </div>
                              <div class="col-4">
                               <div class="id-label"># {{ this.nft.tokenId }}</div>
                              </div>
                            </div>
                            <div class="row ">
                              <div class="col-sm-5 my-auto">
                                <img
                                  width="100%"
                                  [src]="
                                    nft.item_image
                                      ? nft.item_image
                                      : 'assets/images/nft-sample.svg'
                                  "
                                  class="recent-img"
                                />
                              </div>
                              <div class="col-lg-6 my-auto">
                                <div class="recent-description">
                                  <div class="row">
                                    <div class="col-11 p-1">
                                       <p class="product-lv">Lv: 100</p>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-4 p-1">
                                       <img src="assets/images/heart-life.png" class="heart-img">
                                    </div>
                                    <div class="col-4 p-1">
                                       <img src="assets/images/heart-life.png" class="heart-img">
                                    </div>
                                    <div class="col-4 p-1">
                                       <img src="assets/images/heart-life.png" class="heart-img">
                                    </div>
                                    <div class="col-4 p-1">
                                       <img src="assets/images/heart-life.png" class="heart-img">
                                    </div>
                                    <div class="col-4 p-1">
                                       <img src="assets/images/heart-life.png" class="heart-img">
                                    </div>
                                    <div class="col-4 p-1">
                                       <img src="assets/images/heart-life.png" class="heart-img">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 my-auto mt-3">
                                <div class="row">
                                  <div class="col-10">
                                    <div class="btn-price">
                                      <img src="assets/images/price-unit.svg" />457
                                      <span i18n>$2459.89</span>
                                    </div>
                                  </div>
                                  <div class="col-2">
                                   <div class="btn-detail text-right">
                                        &nbsp;
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-sm-3 ms-auto cursor-pointer float-end"
                        (click)="product()"
                        *ngIf="nftList"
                      >
                        <div class="row recent-card my-auto">
                          <div
                            class="col-10 p-0 my-auto"
                            style="height: 45px"
                            i18n
                          >
                            view more on marketplace
                          </div>
                          <div class="col-2 p-0 ms-auto">
                            <div class="btn-detail text-right">&nbsp;</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="dashboard-tabs__pane"
                    id="tab-landListed"
                    [ngClass]="{
                      'dashboard-tabs__pane--active': tabListed === 'landListed'
                    }"
                  >
                    <div class="row m-0">
                      <div *ngIf="!nftList" class="dashboard-loading">
                        <img src="assets/images/loading.svg" width="75px" />
                      </div>
                      <div class="col-lg-4" *ngFor="let nft of nftList">
                        <div class="recent-card">
                          <div class="row mb-3">
                            <div class="col-8">
                              <div class="name-label">{{ nft.animal_name }}</div>
                            </div>
                            <div class="col-4">
                             <div class="id-label"># {{ this.nft.tokenId }}</div>
                            </div>
                          </div>
                          <div class="row ">
                            <div class="col-sm-5 my-auto">
                              <img
                                [src]="
                                  nft.item_image
                                    ? nft.item_image
                                    : 'assets/images/nft-sample.svg'
                                "
                                class="recent-img"
                              />
                            </div>
                            <div class="col-lg-6 my-auto">
                              <div class="recent-description">
                                <div class="row">
                                  <div class="col-11 p-1">
                                     <p class="product-lv">Lv: 100</p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-4 p-1">
                                     <img src="assets/images/heart-life.png" class="heart-img">
                                  </div>
                                  <div class="col-4 p-1">
                                     <img src="assets/images/heart-life.png" class="heart-img">
                                  </div>
                                  <div class="col-4 p-1">
                                     <img src="assets/images/heart-life.png" class="heart-img">
                                  </div>
                                  <div class="col-4 p-1">
                                     <img src="assets/images/heart-life.png" class="heart-img">
                                  </div>
                                  <div class="col-4 p-1">
                                     <img src="assets/images/heart-life.png" class="heart-img">
                                  </div>
                                  <div class="col-4 p-1">
                                     <img src="assets/images/heart-life.png" class="heart-img">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 my-auto mt-3">
                              <div class="row">
                                <div class="col-10">
                                  <div class="btn-price">
                                    <img src="assets/images/price-unit.svg" />457
                                    <span i18n>$2459.89</span>
                                  </div>
                                </div>
                                <div class="col-2">
                                  <a href="product/detail/{{ nft._id }}"
                                    ><div class="btn-detail text-right">
                                      &nbsp;
                                    </div></a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-sm-3 ms-auto cursor-pointer float-end"
                        (click)="product()"
                        *ngIf="nftList"
                      >
                        <div class="row recent-card my-auto">
                          <div
                            class="col-10 p-0 my-auto"
                            style="height: 45px"
                            i18n
                          >
                            view more on marketplace
                          </div>
                          <div class="col-2 p-0 ms-auto">
                            <div class="btn-detail text-right">&nbsp;</div>
                          </div>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #bind let-modal>
      <div class="detail-modal">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title" i18n>
            Link Account
          </h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="row mb-3">
                <div class="col-sm-12 text-center">
                  <p i18n>Do you have a Meta Legend account?</p>
                  <p i18n>Please bind your account email.</p>
                  <p i18n>
                    If not, please download the App and register an account now.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row col-lg-12 mx-auto">
            <div class="col-sm-6">
              <div
                class="btn-cancel"
                (click)="modal.dismiss('Cross click')"
                i18n
              >
                Download
              </div>
            </div>
            <div class="col-sm-6">
              <div
                class="btn-cancel"
                (click)="modal.dismiss('Cross click'); open(gameInfo)"
                i18n
              >
                Bind
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #gameInfo let-modal>
      <div class="detail-modal">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title" i18n>
            Bind Account
          </h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="row mb-3">
                <div class="col-sm-12 text-center">
                  <div class="row">
                    <div class="col-sm-5">
                      <p class="mb-2 setting-label">Email</p>
                    </div>
                    <div class="col-sm-7 col-lg-6 my-auto">
                      <div>
                        <input
                          type="email"
                          name="email"
                          class="setting-input"
                          [ngModel]="facebook"
                          #email
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                      <p class="mb-2 setting-label">Password</p>
                    </div>
                    <div class="col-sm-7 col-lg-6 my-auto">
                      <div>
                        <input
                          type="password"
                          #gamePassword
                          name="gamePassword"
                          class="setting-input"
                          [ngModel]="username"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row col-lg-12 mx-auto">
            <div class="col-sm-6">
              <div
                class="btn-cancel"
                (click)="modal.dismiss('Cross click')"
                i18n
              >
                Cancel
              </div>
            </div>
            <div class="col-sm-6">
              <div
                class="btn-cancel"
                (click)="submit(email.value, gamePassword.value)"
                i18n
              >
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</section>
<ngx-spinner type="ball-clip-rotate-multiple">
  <p class="loading">Loading Awesomeness...</p>
</ngx-spinner>
