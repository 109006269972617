<nav class="navbar navbar-expand-lg navbar-light bg-brown fixed-top">
  <div class="container-fluid px-0">
    <a class="navbar-brand" href="#">
      <div class="logo">
        <img src="assets/images/logo.png" />
      </div>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#main_nav2"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse pc" style="height: 100px; "  id="main_nav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a
            routerLink="/product"
            class="nav-link nav-link js-scroll-trigger"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}"
            ><img
              src="assets/images/marketplace.svg"
              class="menu-icon"
            />Home
          </a>
        </li>
		    <li class="nav-item">
          <a
            routerLink="/product/avatar"
            class="nav-link nav-link js-scroll-trigger"
            routerLinkActive="active"
            ><img
              src="assets/images/avatar.svg"
              class="menu-icon"
            />Avatar
          </a>
        </li>
		    <li class="nav-item">
          <a
            routerLink="/product/npc"
            class="nav-link nav-link js-scroll-trigger"
            routerLinkActive="active"
            ><img
              src="assets/images/ainpc.svg"
              class="menu-icon"
            />AI NPC
          </a>
        </li>
        <li class="nav-item">
          <a
            routerLink="/product/props"
            class="nav-link nav-link js-scroll-trigger"
            routerLinkActive="active"
            ><img src="assets/images/props.svg" class="menu-icon" />Props
          </a>
        </li>
        <li class="nav-item">
          <a
            routerLink="/mystery-box"
            class="nav-link nav-link js-scroll-trigger"
            routerLinkActive="active"
          >
            <img src="assets/images/mbox.svg" class="menu-icon" />
			        Mystery Boxes
          </a>
        </li>
      </ul>

      <ul class="navbar-nav ms-auto">
        <ng-container
          *ngIf="account; then getAccount; else noAccount"
        ></ng-container>
        <ng-template #getAccount>
          <li class="nav-item">
            <a
              routerLink="/profile"
              class="nav-link nav-link js-scroll-trigger btn-contact"
              routerLinkActive="active"
            >
              <div class="wallet-address">
                <img
                  src="assets/images/wallet-address-icon.svg"
                  class="menu-icon"
                />{{ address | slice : 0 : 15 }}...
              </div>
            </a>
          </li>
          <li class="nav-item dropdown nav-divider">
            <a
              class="nav-link dropdown-toggle btn-connect"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="assets/images/icon.svg"
                style="margin-right: 15px"
                width="30"
              />My Account
            </a>
            <ul
              class="dropdown-menu shadow-sm"
              aria-labelledby="navbarDropdown"
            >
              <li>
                <a
                  class="dropdown-item"
                  href="profile"
                  (click)="profileAccount()"
                >
                  <img
                    src="assets/images/dropdown-menu-account-icon.svg"
                    class="menu-icon"
                  />Account
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="profile"
                  (click)="profileInventory()"
                >
                  <img
                    src="assets/images/dropdown-menu-inventory-icon.svg"
                    class="menu-icon"
                  />Inventory
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="profile"
                  (click)="profileWallet()"
                >
                  <img
                    src="assets/images/dropdown-menu-wallet-icon.svg"
                    class="menu-icon"
                  />Wallet
                </a>
              </li>

              <li>
                <a class="dropdown-item" href="offer/made">
                  <img
                    src="assets/images/dropdown-menu-made-icon.svg"
                    class="menu-icon"
                  />Offers made
                </a>
              </li>

              <li>
                <a class="dropdown-item" href="offer/received">
                  <img
                    src="assets/images/dropdown-menu-receive-icon.svg"
                    class="menu-icon"
                  />Offers received
                </a>
              </li>
              <li *ngIf="!verified">
                <a class="dropdown-item" (click)="open(bind)">
                  <img
                    src="assets/images/dropdown-menu-bind-icon.svg"
                    class="menu-icon"
                  />Bind Account
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#" (click)="logout()">
                  <img
                    src="assets/images/dropdown-menu-setting-icon.svg"
                    class="menu-icon"
                  />Logout
                </a>
              </li>
            </ul>
          </li>
        </ng-template>
        <ng-template #bind let-modal>
          <div class="detail-modal">
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title" i18n>
                Link Game Account
              </h4>
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row mb-3">
                    <div class="col-sm-12 text-center">
                      <p i18n>Do you have a Meta Legend account?</p>
                      <p i18n>Please bind your game account email.</p>
                      <p i18n>
                        If not, please download the App and register an account
                        now.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row col-lg-12 mx-auto">
                <div class="col-sm-6">
                  <div
                    class="btn-cancel"
                    (click)="modal.dismiss('Cross click')"
                    i18n
                  >
                    Download
                  </div>
                </div>
                <div class="col-sm-6">
                  <div
                    class="btn-cancel"
                    (click)="modal.dismiss('Cross click'); open(gameInfo)"
                    i18n
                  >
                    Bind
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template #gameInfo let-modal>
          <div class="detail-modal">
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title" i18n>
                Bind Game Account
              </h4>
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row mb-3">
                    <div class="col-sm-12 text-center">
                      <div class="row">
                        <div class="col-sm-5">
                          <p class="mb-2 setting-label">Game Email</p>
                        </div>
                        <div class="col-sm-7 col-lg-6 my-auto">
                          <div>
                            <input
                              type="email"
                              name="email"
                              class="setting-input"
                              [ngModel]="facebook"
                              #email
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <p class="mb-2 setting-label">Game Password</p>
                        </div>
                        <div class="col-sm-7 col-lg-6 my-auto">
                          <div>
                            <input
                              type="password"
                              #gamePassword
                              name="gamePassword"
                              class="setting-input"
                              [ngModel]="username"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row col-lg-12 mx-auto">
                <div class="col-sm-6">
                  <div
                    class="btn-cancel"
                    (click)="modal.dismiss('Cross click')"
                    i18n
                  >
                    Cancel
                  </div>
                </div>
                <div class="col-sm-6">
                  <div
                    class="btn-cancel"
                    (click)="submit(email.value, gamePassword.value)"
                    i18n
                  >
                    Submit
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template #noAccount>
          <li class="nav-item">
            <a
              routerLink="/login"
              class="nav-link nav-link js-scroll-trigger btn-connect"
              routerLinkActive="active"
            >
              <img
                src="assets/images/connect-wallet.svg"
                class="menu-icon"
              />Connect Wallet
            </a>
          </li>

         <li class="nav-item">
            <a
              routerLink="/login"
              class="nav-link nav-link js-scroll-trigger btn-transparent"
              routerLinkActive="active"
            >
              <img src="assets/images/icon.svg" height="30" />
            </a>
          </li> 
        </ng-template>
      </ul>
    </div>
    <div class="collapse navbar-collapse mobile" style="height: 100px; "  id="main_nav2">
      <ul class="navbar-nav" data-bs-toggle="collapse" data-bs-target="#main_nav2" *ngIf="!mobileDropDownShow" (click)="menuShow()">
        <li class="nav-item">
          <a
            routerLink="/product"
            class="nav-link nav-link js-scroll-trigger"
            ><img
              src="assets/images/marketplace.svg"
              class="menu-icon"
            />Home
          </a>
        </li>
		    <li class="nav-item">
          <a
            routerLink="/product/avatar"
            class="nav-link nav-link js-scroll-trigger"
            ><img
              src="assets/images/avatar.svg"
              class="menu-icon"
            />Avatar
          </a>
        </li>
		    <li class="nav-item">
          <a
            routerLink="/product/npc"
            class="nav-link nav-link js-scroll-trigger"
            ><img
              src="assets/images/ainpc.svg"
              class="menu-icon"
            />AI NPC
          </a>
        </li>
        <li class="nav-item">
          <a
            routerLink="/product/props"
            class="nav-link nav-link js-scroll-trigger"
            ><img src="assets/images/props.svg" class="menu-icon" />Props
          </a>
        </li>
        <li class="nav-item">
          <a
            routerLink="/mystery-box"
            class="nav-link nav-link js-scroll-trigger"
          >
            <img src="assets/images/mbox.svg" class="menu-icon" />
			        Mystery Boxes
          </a>
        </li>
      </ul>

      <ul class="navbar-nav ms-auto">
        <ng-container
          *ngIf="account; then getAccount2; else noAccount2"
        ></ng-container>
        <ng-template #getAccount2>
          <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#main_nav2" *ngIf="!mobileDropDownShow">
            <a
              routerLink="/profile"
              class="nav-link nav-link js-scroll-trigger btn-contact"
            >
              <div class="wallet-address">
                <img
                  src="assets/images/wallet-address-icon.svg"
                  class="menu-icon"
                />{{ address | slice : 0 : 15 }}...
              </div>
            </a>
          </li>
          <li class="nav-item nav-divider" style="display: block;">
            <a
              class="nav-link dropdown-toggle btn-connect"
              href="#"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#dr2"
              aria-expanded="false"
              (click)="toggleShow()"
            >
              <img
                src="assets/images/icon.svg"
                style="margin-right: 15px"
                width="30"
              />My Account
            </a>
            <ul
              class="shadow-sm collapse collapse-menu"
              id="dr2"
            >
              <li>
                <a
                  class="dropdown-item"
                  href="profile"
                  (click)="profileAccount()"
                >
                  <img
                    src="assets/images/dropdown-menu-account-icon.svg"
                    class="menu-icon"
                  />Account
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="profile"
                  (click)="profileInventory()"
                >
                  <img
                    src="assets/images/dropdown-menu-inventory-icon.svg"
                    class="menu-icon"
                  />Inventory
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="profile"
                  (click)="profileWallet()"
                >
                  <img
                    src="assets/images/dropdown-menu-wallet-icon.svg"
                    class="menu-icon"
                  />Wallet
                </a>
              </li>

              <li>
                <a class="dropdown-item" href="offer/made">
                  <img
                    src="assets/images/dropdown-menu-made-icon.svg"
                    class="menu-icon"
                  />Offers made
                </a>
              </li>

              <li>
                <a class="dropdown-item" href="offer/received">
                  <img
                    src="assets/images/dropdown-menu-receive-icon.svg"
                    class="menu-icon"
                  />Offers received
                </a>
              </li>
              <li *ngIf="!verified">
                <a class="dropdown-item" (click)="open(bind)">
                  <img
                    src="assets/images/dropdown-menu-bind-icon.svg"
                    class="menu-icon"
                  />Bind Account
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#" (click)="logout()">
                  <img
                    src="assets/images/dropdown-menu-setting-icon.svg"
                    class="menu-icon"
                  />Logout
                </a>
              </li>
            </ul>
          </li>
        </ng-template>
        <ng-template #bind2 let-modal>
          <div class="detail-modal">
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title" i18n>
                Link Game Account
              </h4>
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row mb-3">
                    <div class="col-sm-12 text-center">
                      <p i18n>Do you have a Meta Legend account?</p>
                      <p i18n>Please bind your game account email.</p>
                      <p i18n>
                        If not, please download the App and register an account
                        now.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row col-lg-12 mx-auto">
                <div class="col-sm-6">
                  <div
                    class="btn-cancel"
                    (click)="modal.dismiss('Cross click')"
                    i18n
                  >
                    Download
                  </div>
                </div>
                <div class="col-sm-6">
                  <div
                    class="btn-cancel"
                    (click)="modal.dismiss('Cross click'); open(gameInfo)"
                    i18n
                  >
                    Bind
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template #gameInfo2 let-modal>
          <div class="detail-modal">
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title" i18n>
                Bind Game Account
              </h4>
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row mb-3">
                    <div class="col-sm-12 text-center">
                      <div class="row">
                        <div class="col-sm-5">
                          <p class="mb-2 setting-label">Game Email</p>
                        </div>
                        <div class="col-sm-7 col-lg-6 my-auto">
                          <div>
                            <input
                              type="email"
                              name="email"
                              class="setting-input"
                              [ngModel]="facebook"
                              #email
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <p class="mb-2 setting-label">Game Password</p>
                        </div>
                        <div class="col-sm-7 col-lg-6 my-auto">
                          <div>
                            <input
                              type="password"
                              #gamePassword
                              name="gamePassword"
                              class="setting-input"
                              [ngModel]="username"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row col-lg-12 mx-auto">
                <div class="col-sm-6">
                  <div
                    class="btn-cancel"
                    (click)="modal.dismiss('Cross click')"
                    i18n
                  >
                    Cancel
                  </div>
                </div>
                <div class="col-sm-6">
                  <div
                    class="btn-cancel"
                    (click)="submit(email.value, gamePassword.value)"
                    i18n
                  >
                    Submit
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template #noAccount2>
          <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#main_nav2">
            <a
              routerLink="/login"
              class="nav-link nav-link js-scroll-trigger btn-connect"
              routerLinkActive="active"
            >
              <img
                src="assets/images/connect-wallet.svg"
                class="menu-icon"
              />Connect Wallet
            </a>
          </li>

         <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#main_nav2">
            <a
              routerLink="/login"
              class="nav-link nav-link js-scroll-trigger btn-transparent"
              routerLinkActive="active"
            >
              <img src="assets/images/icon.svg" height="30" />
            </a>
          </li> 
        </ng-template>
      </ul>
    </div>
  </div>
</nav>
