<section style="margin-top: 60px">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 my-auto">
        <div class="row detail-bg">
          <div class="col-lg-5 my-auto">
            <div>
              <h1 class="detail-header mb-3" style="font-size: 32px;">Mystery Box</h1>
              <p style="margin-bottom: 40px">Mystery Box contains various Meta Legend NFTs. The higher the quality of a mystery box, the higher your chances are to get a high-quality Meta Legend NFT.</p>
            </div>
            <div class="row">
              <div class="col-4">
                <div class="card-box">
                  <img src="assets/images/lion.svg" width="100%">
                </div>
              </div>
              <div class="col-4">
                <div class="card-box">
                  <img src="assets/images/elephant.svg" width="100%">
                </div>
              </div>
              <div class="col-4">
                <div class="card-box">
                  <img src="assets/images/corcodile.svg" width="100%">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="btn-buy cursor-pointer" (click)="detail()">
                  <!-- <img src="assets/images/price-unit.svg">  -->
                  <div>450 polygon (100 USD)</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7 my-auto text-center  ">
            <img src="assets/images/mystery-box-1.svg" width="70%">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
